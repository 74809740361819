import React, { useEffect } from "react";
import "./AdminContactUs.css";
import * as API from "../../../Components/util/url";
import { useState } from "react";
import ConfirmationModal from "../../../Common/ConfirmationModal/ConfirmationModal";
import { set } from "react-ga";

import CommonModal from "../../../Common/CommonModal/CommonModal";
const AdminContactUS = () => {
  const [contactdetails, setContactDetails] = useState(null);
  const [modalShow, setModalShow] = React.useState(false);
  const [resolvedStates, setResolvedStates] = useState({});
  const [loading, setLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [message, setMessage] = useState({
    msg: "",
    urlmodaldata: "",
  });

  // api integration
  const contactUsAdmin = async (data) => {
    try {
      const response = await API.AdminContactus({
        pageNumber: 1,
        pageSize: 10,
      });
      setContactDetails(response.data.resultList);
      console.log(response, "admin contact us");
    } catch (error) {
      console.log(error);
    }
  };
  useEffect((value) => {
    contactUsAdmin();
  }, []);

  // Respond api
  const RespondAPI = async (id) => {
    setLoading(true);
    try {
      const response = await API.AdminContactusrespond(id);

      console.log(response);
      if (response.data.status === true) {
        setResolvedStates((prevState) => ({
          ...prevState,
          [id]: true, // Update the specific item as resolved
        }));
      } else {
        console.log("Failed to mark as resolved");
      }
      if (response.data.status === true) {
        setMessage({
          msg: response.data.message,
          urlmodaldata: "",
        });
        setShowSuccessModal(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const savedResolvedStates = JSON.parse(localStorage.getItem("resolvedStates")) || {};
    setResolvedStates(savedResolvedStates);
  }, []);

  // Save the resolved states to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem("resolvedStates", JSON.stringify(resolvedStates));
  }, [resolvedStates]);
  return (
    <div className="mt-4">
      <h1 className="mt-2" style={{ textAlign: "center" }}>
        Contact Us
      </h1>
      <div className="container">
        <div className="row ">
          {contactUsAdmin && contactdetails && contactdetails.length > 0 ? (
            contactdetails.map((state) => (
              <div
                className="col-lg-6 col-md-6 col-12 user-contactus-div"
                key={state.id}
              >
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-12">
                    <div className="user-contactus-data">
                      <h6>
                        Name: <span>{state.senderName}</span>
                      </h6>
                      <h6>
                        Date: <span>{state.date}</span>
                      </h6>

                      <h6>
                        Email-Id: <span>{state.senderEmail}</span>
                      </h6>
                      <h6>
                        Phone no: <span>{state.senderPhoneNumber}</span>
                      </h6>
                      <h6>
                        Subject: <span>{state.subject}</span>
                      </h6>
                      <h6 className="ques">
                        Question: <span>{state.description}</span>
                      </h6>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="user-contactus-btn">
                      {/* <div className="respond mt-2">
                      <button>Respond</button>
                    </div> */}
                      <div className="delete mt-2">
                        <button
                          type="button"
                          onClick={() => setModalShow(true)}
                        >
                          Delete
                        </button>
                      </div>

                      <div className="resolve mt-2">
                        <button
                          onClick={() => RespondAPI(state.msgId)}
                          disabled={resolvedStates[state.msgId]} // Disable button only for the specific item
                        >
                          {resolvedStates[state.msgId]
                            ? "Resolved"
                            : "Mark as Resolved?"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {modalShow && (
                  <ConfirmationModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    contactdetails={state}
                  />
                )}
                {/* {modalShow && (
                  <ConfirmationModalRespond
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    contactdetails={state}
                  />
                )} */}
              </div>
            ))
          ) : (
            <div className="col-12">
              <p>No contact details available.</p>
            </div>
          )}
        </div>
      </div>
      <CommonModal
        show={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
        message={message}
      />
    </div>
  );
};

export default AdminContactUS;
