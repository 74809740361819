import React from 'react'

const AdminDashboard = () => {
  return (
    <div>
      <h1>AdminDashboard</h1>
    </div>
  )
}

export default AdminDashboard
