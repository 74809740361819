import React, { useState } from "react";
import NavDropdown from "react-bootstrap/NavDropdown";
import { NavLink, useNavigate } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import { RxCross2 } from "react-icons/rx";
import {
  RiHome4Line,
  RiCustomerServiceLine,
  RiArrowDownSLine,
  RiArrowLeftSLine,
  RiDashboardFill,
  RiMenu2Fill,
} from "react-icons/ri";
import { MdHelpOutline } from "react-icons/md";
// import "../AdminDashBoardNavBar/AdminDashBoardNavBar.css";

const OwnerDashBoardNavBar = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    // setDropdownToggle1(false);
    // setDropdownToggle2(false);
  };
  const handleShow = () => setShow(true);

  const [dropdowntoggle1, setDropdownToggle1] = useState(false);
  const [dropdowntoggle2, setDropdownToggle2] = useState(false);

  const toggleDropdown1 = () => {
    setDropdownToggle1(!dropdowntoggle1);
    setDropdownToggle2(false);
  };

  const toggleDropdown2 = () => {
    setDropdownToggle2(!dropdowntoggle2);
    setDropdownToggle1(false);
  };
  const logoutHandler = () => {
    // Clear login state
    // setIsLoggedIn(false);
    // setUserName("");

    // Remove login status from localStorage
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("userName");

    navigate("/"); // Redirect to homepage after logout
  };
  const navigate = useNavigate();
  return (
    <>
      <div className="dash-navbar">
        <div>
          <NavLink to="/">
            <img
              src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1720704397/afhlogo_zfiv9i.png"
              alt="dashboard-logo"
              className="company-logo"
            />
          </NavLink>
        </div>
        <div className="profile admin-user-profile">
          {/* <div>
            <i
              className="fa-regular fa-bell dashboard-bell"
              onClick={handleShow}
            ></i>
          </div> */}

          <div className="profile">
            <div>
              <i className="fa-solid fa-circle-user dashboard-userprofile"></i>
            </div>

            <NavDropdown
              id="navbarScrollingDropdown"
              className="drop-down-arrow"
            >
              <NavDropdown.Item
                href="#action3"
                className="navbar-dropdown-home"
              >
                Home
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                // href="#action4"
                className="navbar-dropdown-home"
              >
               <div
                  className="me-5"
                  style={{ color: "black", cursor: "pointer" }}
                  onClick={logoutHandler}
                >
                  Logout
                </div>
              </NavDropdown.Item>
            </NavDropdown>
          </div>
        </div>
        <div onClick={handleShow} className="d-lg-none ">
          <RiMenu2Fill className=" offcanvas-admin-menu" />
        </div>
      </div>

      <Offcanvas
        show={show}
        onHide={handleClose}
        className="offcanvas-admin-menu custom-offcanvas"
      >
        <Offcanvas.Body>
          <div className="d-flex justify-content-between">
            <div className="profile admin-userprofile">
              <div>
                <i
                  className="fa-regular fa-bell dashboard-bell"
                  onClick={handleShow}
                ></i>
              </div>

              <div className="profile">
                <div>
                  <i className="fa-solid fa-circle-user dashboard-userprofile"></i>
                </div>

                <NavDropdown
                  id="navbarScrollingDropdown"
                  className="drop-down-arrow"
                >
                  <NavDropdown.Item
                    href="#action3"
                    className="navbar-dropdown-home"
                  >
                    Home
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item
                    href="#action4"
                    className="navbar-dropdown-home"
                  >
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              </div>
            </div>

            <div>
              <div className="cross-close" onClick={handleClose}>
                <RxCross2 />
              </div>{" "}
            </div>
          </div>
          <hr />
          <NavLink to="/owner/dashboard" className="side-menu-bar-dec">
            <div className="side-menu-bar-one side-menu-bar-dec">
              <RiDashboardFill className="menu-icon" />
              <span>Dashboard</span>
            </div>
          </NavLink>

          <div
            className={`dropdown d-flex justify-content-between side-menu-bar-one side-menu-bar-dec ${
              dropdowntoggle1 ? "active" : ""
            }`}
            onClick={() => {
              toggleDropdown1();
            }}
            aria-expanded={dropdowntoggle1}
          >
            <span>
              <RiHome4Line className="menu-icon me-2" />
              <span>My Homes</span>
            </span>
            <span>
              <span>
                {dropdowntoggle1 ? (
                  <RiArrowDownSLine className=" arrow-icon" />
                ) : (
                  <RiArrowLeftSLine className=" arrow-icon" />
                )}
              </span>
            </span>
          </div>
          <span>
            <>
              {dropdowntoggle1 ? (
                <span>
                  <div className="side-menu-bar-one-drop">
                    <NavLink
                      to="/owner/listed-homes"
                      className="owner-dropdown-item mt-2"
                    >
                      My Listed Homes
                    </NavLink>
                  </div>
                  <div className="side-menu-bar-one-drop">
                    <NavLink
                      to="/owner/add-new-home"
                      className="owner-dropdown-item mt-2"
                    >
                      List New Homes
                    </NavLink>
                  </div>
                </span>
              ) : (
                " "
              )}
            </>
          </span>

          <NavLink to="/owner/afh-services" className="side-menu-bar-dec ">
            <div className="side-menu-bar-one side-menu-bar-dec">
              <RiCustomerServiceLine className="menu-icon" />
              <span>AFH Services</span>
            </div>
          </NavLink>
          <div
            className={`dropdown d-flex justify-content-between side-menu-bar-one side-menu-bar-dec ${
              dropdowntoggle2 ? "active" : ""
            }`}
            onClick={() => {
              toggleDropdown2();
            }}
            aria-expanded={dropdowntoggle2}
          >
            <span>
              <MdHelpOutline className="menu-icon me-2" />
              <span>Help</span>
            </span>
            <span>
              <span>
                {dropdowntoggle2 ? (
                  <RiArrowDownSLine className=" arrow-icon" />
                ) : (
                  <RiArrowLeftSLine className=" arrow-icon" />
                )}
              </span>
            </span>
          </div>

          <span>
            <>
              {dropdowntoggle2 ? (
                <span>
                  <div className="side-menu-bar-one-drop">
                    <NavLink
                      to="/owner/faq"
                      className="owner-dropdown-item mt-2"
                    >
                      FAQs
                    </NavLink>
                  </div>
                  {/* <div className="side-menu-bar-one-drop">
                    <NavLink to="" className="owner-dropdown-item mt-2">
                      Contact
                    </NavLink>
                  </div> */}
                </span>
              ) : (
                " "
              )}
            </>
          </span>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default OwnerDashBoardNavBar;
