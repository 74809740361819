import React, { useEffect, useRef, useState } from "react";
import "./ListedHomeLandingPage.css";
import CommonNavbar from "../../../Pages/CommonNavbar";
import Footer from "../../../Pages/Footer";
import Reviews from "../../../Common/Reviews/Reviews";
import { Swiper, SwiperSlide } from "swiper/react";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import CommonModal from "../../../Common/CommonModal/CommonModal";
import CommonModalFail from "../../../Common/CommonModal/CommonModalFail";
import Carousel from "react-bootstrap/Carousel";
// import ExampleCarouselImage from 'components/ExampleCarouselImage';
import * as API from "../../../Components/util/url";
import SwiperCore, {
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
} from "swiper";
import "swiper/css";
import leaflet from "leaflet";
import { Link, NavLink, useLocation } from "react-router-dom";
import LandingMapIntegration from "../../../Common/LandingMapIntegration/LandingMapIntegration";
import SimilarHome from "../../SimilarHome/SimilarHome";
const ReadMore = ({ text = "", maxLength }) => {
  const [isTruncated, setIsTruncated] = useState(true);

  const toggleTruncate = () => {
    setIsTruncated(!isTruncated);
  };

  // Ensure text is defined and not null
  if (!text) {
    return null; // or return some default content or message
  }

  // Split the text into paragraphs
  const paragraphs = text.split("\n\n");

  // Truncate text based on maxLength
  const truncatedText = isTruncated
    ? paragraphs.map((para, index) => {
        // Check if the cumulative length is within the maxLength
        const isWithinLimit = para.length <= maxLength;
        maxLength -= para.length;
        return (
          <p key={index}>{isWithinLimit ? para : para.slice(0, maxLength)}</p>
        );
      })
    : paragraphs.map((para, index) => <p key={index}>{para}</p>);

  return (
    <div>
      {truncatedText}
      <span
        onClick={toggleTruncate}
        style={{ color: "blue", cursor: "pointer" }}
      >
        {isTruncated ? "Read more" : "Read less"}
      </span>
    </div>
  );
};

const ListedHomeLandingPage = () => {
  const slides = [
    {
      imageUrl:
        "https://res.cloudinary.com/dbtsrjssc/image/upload/v1724329884/the-burlington-creek-senior-living-community-exterior-kansas-city_fcw4zl.jpg",
      name: "Coterie Hudson Yards",
      address: "505 W 35th St, New York, NY 10018",
      reviewScore: 9.9,
      reviewCount: 81555,
      description:
        "Discover a new vision of luxury senior living, now brought to life at...",
    },
    {
      imageUrl:
        "https://res.cloudinary.com/dbtsrjssc/image/upload/v1722340561/images_1.jpg",
      name: "Coterie Hudson Yards",
      address: "505 W 35th St, New York, NY 10018",
      reviewScore: 9.9,
      reviewCount: 81,
      description:
        "Discover a new vision of luxury senior living, now brought to life at...",
    },
    {
      imageUrl:
        "https://res.cloudinary.com/dbtsrjssc/image/upload/v1724329884/the-burlington-creek-senior-living-community-exterior-kansas-city_fcw4zl.jpg",
      name: "Coterie Hudson Yards",
      address: "505 W 35th St, New York, NY 10018",
      reviewScore: 9.9,
      reviewCount: 81,
      description:
        "Discover a new vision of luxury senior living, now brought to life at...",
    },
    {
      imageUrl:
        "https://res.cloudinary.com/dbtsrjssc/image/upload/v1724329884/the-burlington-creek-senior-living-community-exterior-kansas-city_fcw4zl.jpg",
      name: "Coterie Hudson Yards",
      address: "505 W 35th St, New York, NY 10018",
      reviewScore: 9.9,
      reviewCount: 81,
      description:
        "Discover a new vision of luxury senior living, now brought to life at...",
    },
    {
      imageUrl:
        "https://res.cloudinary.com/dbtsrjssc/image/upload/v1724329884/the-burlington-creek-senior-living-community-exterior-kansas-city_fcw4zl.jpg",
      name: "Coterie Hudson Yards",
      address: "505 W 35th St, New Yaork, NY 10018",
      reviewScore: 9.9,
      reviewCount: 81,
      description:
        "Discover a new vision of luxury senior living, now brought to life at...",
    },
    // Add more housing data here
  ];
  const location = useLocation();
  
  const { homeDetails } = location.state || {};
  const { nearbyhome } = location.state || {};
  const [homeDetail, setHomeDetail] = useState(null);
  const [nearhomeDetail, setNearHomeDetail] = useState(false);
  const [homeamenities, setHomeamenities] = useState([]);
  const [homeDetailImage, setHomeDetailImage] = useState([]);
  const [homeservices, setHomeservices] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailureModal, setShowFailureModal] = useState(false);
  const [message, setMessage] = useState({
    msg: "",
    urlmodaldata: "",
  });
  console.log(nearbyhome, "nearbyhome");

  const chunks = [];
  for (let i = 0; i < slides.length; i += 3) {
    chunks.push(slides.slice(i, i + 3));
  }
  const [wishlist, setWishlist] = useState({});
  const wistlistheart = (index) => {
    setWishlist((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  // api integration
  const GetHomeById = async (id, isNearby = false) => {
    try {
      const response = await API.GetHomeById(id);
      setHomeDetail(response.data.homeData);
      setHomeDetailImage(response.data.homeData.imagePath);
      setHomeamenities(response.data.amenities);
      setHomeservices(response.data.services);
      console.log(response.data.homeData, "homeDetail");
      console.log(response.data.homeData.imagePath, "homeDetail image");
      console.log(response.data.amenities, "amenities");
      console.log(response.data.services, "services");
      if (isNearby) {
        setNearHomeDetail(response.data.homeData);
        console.log(response.data.homeData, "neardeatil");

        setNearHomeDetail(response.data.amenities);
        console.log(response.data.amenities, "amenities near home");

        setNearHomeDetail(response.data.services);
      }
    } catch (error) {
      console.error("Error fetching home details:", error);
    }
    // } catch (error) {
    //   console.error("Error fetching home details:", error);
    // }
  };

  useEffect(() => {
    if (homeDetails) {
      GetHomeById(homeDetails.stayId);
    }
    if (nearbyhome) {
      GetHomeById(nearbyhome.stayId, true);
    }
  }, [homeDetails, nearbyhome]);

  if ((!homeDetails && !nearbyhome) || (!homeDetail && !nearhomeDetail)) {
    return <div>No details available</div>;
  }

  // formik for check availiblity
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Full Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phoneNumber: Yup.string()
      .matches(/^[0-9]+$/, "Phone number must be numeric")
      .min(10, "Phone number must be at least 10 digits")
      .required("Phone number is required"),
  });

  // check Avaliblity
  const checkavaliblity = async (data) => {
    try {
      const checkresp = await API.CheckAvaliblity({
        email: data.email,
        name: data.name,
        phoneNumber: data.phoneNumber,
        homeName: homeDetail.pgName,
      });
      console.log(checkresp);
      console.log(checkresp.data.status, "12345");
      console.log(checkresp.data.message, "12345");

      if (checkresp.data.status === true) {
        setMessage({
          msg: checkresp.data.message,
          urlmodaldata: "/list-home-LandingPage/:stayId",
        });
        setShowSuccessModal(true);
      } else {
        setMessage({
          msg: checkresp.data.message,
          urlmodaldata: '/list-home-LandingPage/:stayId"',
        });
        setShowFailureModal(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // console.log("homeDetail", homeDetail);

  return (
    <>
      <div style={{ backgroundColor: "#8080800d" }}>
        <CommonNavbar style={{ backgroundColor: "#2A342C" }} />

        <div className="container">
          <div className="row">
            {/* {homeDetail.map &&((homedata,id) => ( */}
            <div className="col-lg-8 col-md-12 col-12">
              <div className="list-home-sec">
                <h1 className="landingheading">{homeDetail.metatitle}</h1>
                {/* <p>{homeDetail.address} </p>
                <p>Zip Code: {homeDetail.zipCode}</p> */}
                {/* <p>
                  Website:
                  <Link to={homeDetail.weburl} target="_blank">
                    {" "}
                    {homeDetail.weburl}
                  </Link>
                </p> */}
                <div className="reviews">
                  <div className="reviewbtn">
                    {parseFloat(homeDetail.avgRating)}
                  </div>

                  <p className="reviewheading">
                    Review Score <span>{homeDetail.viewCount} Reviews</span>
                  </p>
                  <NavLink
                    to="/addreviwes"
                    state={{
                      stayId: homeDetails.stayId,
                      pgName: homeDetail.pgName,
                    }}
                    className="addreviewslink"
                  >
                    Add Reviews
                  </NavLink>
                </div>
                <Carousel>
                  {homeDetailImage && homeDetailImage.length > 0 ? (
                    homeDetailImage.map((imagePath, index) => {
                      console.log("Image path:", imagePath);
                      return (
                        <Carousel.Item key={index}>
                          <a
                            href={imagePath}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={imagePath}
                              className="list-home-img"
                              alt={`Slide ${index + 1}`}
                              style={{ width: "100%", height: "auto" }} // Ensure proper size
                            />
                          </a>
                        </Carousel.Item>
                      );
                    })
                  ) : (
                    <p>No images available</p>
                  )}
                </Carousel>

                {/* Content Below Carousel */}
                <h3>{homeDetail.ownerName}</h3>
                <p
                // style={{fontWeight:'500',fontSize:'18px'}}
                >
                  {homeDetail.metadescription}
                </p>
                <span>
                  {" "}
                  <ReadMore text={homeDetail.description} maxLength={1000} />
                </span>

                {/* amenities*/}
                <div className="home-services mt-3">
                  <div className="row">
                    <div className="col-md-12">
                      {/* Services Section */}
                      <div className="home-services ">
                        <div className="row mt-3">
                          <h3>Services</h3>
                          {homeservices &&
                            homeservices.map((service) => (
                              <div className="col-lg-5 col-md-5 col-12">
                                <ul className="home-services-list">
                                  <li>
                                    <i className="fa-solid fa-circle-check"></i>{" "}
                                    {service}
                                  </li>
                                </ul>
                              </div>
                            ))}
                        </div>
                      </div>

                      {/* amenities*/}
                      {/* <div className="home-services mt-3 "> */}
                      {/* <div className="row">
                        <h3>Amenities & Activities</h3>
                        <div className="home-service-activities">
                          {homeamenities &&
                            homeamenities.map((amenity, index) => (
                              <div
                                key={index}
                                className="col-lg-4 col-md-6 col-12"
                              >
                                <ul className="home-services-list">
                                  <li>
                                    <i className="fa-solid fa-circle-check"></i>{" "}
                                    {amenity}
                                  </li>
                                </ul>
                              </div>
                            ))}
                        </div>
                      </div> */}
                      {/* </div> */}
                    </div>
                  </div>
                  <div className="row mt-3">
                    <h3>Amenities & Activities</h3>
                    <div className="home-service-activities">
                      {homeamenities &&
                        homeamenities.map((amenity, index) => (
                          <div
                            key={index}
                            className="col-lg-6 col-md-6 col-12 "
                          >
                            <ul className="home-services-list">
                              <li>
                                <i className="fa-solid fa-circle-check"></i>{" "}
                                {amenity}
                              </li>
                            </ul>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                <Reviews homeDataReviews={homeDetails} />
              </div>
            </div>

            {/* // ))} */}

            {/* Google Map Section */}
            <div className="col-lg-4 col-md-8 col-12 mt-4 mb-5 pricing-map">
              <div className="listedHomeLanding-form">
                <h3>Get Pricing & Check Availability</h3>
                <Formik
                  initialValues={{ name: "", email: "", phoneNumber: "" }}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    checkavaliblity(values);
                  }}
                >
                  {({ handleSubmit }) => (
                    <Form className="mt-3">
                      <div>
                        <Field
                          type="text"
                          id="name"
                          name="name"
                          placeholder="Full Name*"
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="error"
                        />
                      </div>
                      <br />
                      <div>
                        <Field
                          type="text"
                          id="email"
                          name="email"
                          placeholder="Email*"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="error"
                        />
                      </div>
                      <br />
                      <div>
                        <Field
                          type="text"
                          id="phoneNumber"
                          name="phoneNumber"
                          placeholder="Phone Number*"
                        />
                        <ErrorMessage
                          name="phoneNumber"
                          component="div"
                          className="error"
                        />
                      </div>
                      <br />
                      <div className="listedformbtndiv">
                        <button type="submit" className="listedformbtn">
                          Submit
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>

              {homeDetail && (
                <LandingMapIntegration homePosition={homeDetail} />
              )}
            </div>
          </div>
          {/* <div className="row mobile-view-none">
            <div className="col-md-12 col-lg-none "> */}
          {/* Services Section */}
          {/* <div className="home-services ">
                <div className="row">
                  <h3>Services</h3>
                  {homeservices &&
                    homeservices.map((service) => (
                      <div className="col-lg-4 col-md-6 col-12">
                        <ul className="home-services-list">
                          <li>
                            <i className="fa-solid fa-circle-check"></i>{" "}
                            {service}
                          </li>
                        </ul>
                      </div>
                    ))}
                </div>
              </div> */}

          {/* amenities*/}
          {/* <div className="home-services mt-3 ">
                <div className="row">
                  <h3>Amenities & Activities</h3>
                  {homeamenities &&
                    homeamenities.map((amenity, index) => (
                      <div key={index} className="col-lg-4 col-md-6 col-12">
                        <ul className="home-services-list">
                          <li>
                            <i className="fa-solid fa-circle-check"></i>{" "}
                            {amenity}
                          </li>
                        </ul>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div> */}
        </div>

        {homeDetail && <SimilarHome homenearby={homeDetail} />}

        <CommonModal
          show={showSuccessModal}
          onHide={() => setShowSuccessModal(false)}
          message={message}
        />
        <CommonModalFail
          show={showFailureModal}
          onHide={() => setShowFailureModal(false)}
          message={message}
        />
        <Footer />
      </div>
    </>
  );
};

export default ListedHomeLandingPage;
