import React, { useState } from "react";
import "./AllListing.css";

const AllListing = () => {
  const [isChecked, setIsChecked] = useState(false);

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };
  //
  const listings = [
    {
      srNo: 1,
      homeName: "My Home",
      ownerName: "Gulfam",
      city: "Miami",
      contact: "123456789",
      publish: "",
      availability: "",
      ownerEmail: "ahmedgulfam113@gmail.com",
      enquiry: "fa-solid fa-bell",
      zipCode: "41000",
      mapCoordinator: "null,null",
      date: "08-10-2023",
      deleteIcon: "fa-regular fa-trash-can",
    },
    {
      srNo: 2,
      homeName: "Another Home",
      ownerName: "John Doe",
      city: "New York",
      contact: "987654321",
      publish: "",
      availability: "",
      ownerEmail: "john.doe@example.com",
      enquiry: "fa-solid fa-bell",
      zipCode: "10001",
      mapCoordinator: "40.7128,-74.0060",
      date: "07-15-2023",
      deleteIcon: "fa-regular fa-trash-can",
    },
    // Add more rows as needed
  ];

  return (
    <div className="table_responsive mt-5">
      <table className="tablemain">
        <thead className="tableheader">
          <tr>
            <th>
              <input
                type="checkbox"
                name="table-checkbox"
                id="table-checkbox-all"
              />
            </th>
            <th>Sr.No</th>
            <th>Home Name</th>
            <th>Owner Name</th>
            <th>City</th>
            <th>Contact</th>
            <th>Publish</th>
            <th>Availability</th>
            <th>Owner Email</th>
            <th>Enquiry</th>
            <th>Zip Code</th>
            <th>Map Coordinator</th>
            <th>Date</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {listings.map((listing, index) => (
            <tr key={index}>
              <td>
                <input
                  type="checkbox"
                  name="table-checkbox"
                  id={`table-checkbox-${index}`}
                />
              </td>
              <td>{listing.srNo}</td>
              <td>{listing.homeName}</td>
              <td>{listing.ownerName}</td>
              <td>{listing.city}</td>
              <td>{listing.contact}</td>
              <td>
                {" "}
                <div className="container">
                  <label className="switch">
                    <input
                      type="checkbox"
                      id="checkbox"
                      checked={isChecked}
                      onChange={handleToggle}
                    />
                    <div
                      className={`slider round ${isChecked ? "checked" : ""}`}
                    ></div>
                  </label>
                </div>
              </td>
              <td>
                <div className="container">
                  <label className="switch">
                    <input
                      type="checkbox"
                      id="checkbox"
                      checked={isChecked}
                      onChange={handleToggle}
                    />
                    <div
                      className={`slider round ${isChecked ? "checked" : ""}`}
                    ></div>
                  </label>
                </div>
              </td>
              <td>{listing.ownerEmail}</td>
              <td>
                <i className={listing.enquiry}></i>
              </td>
              <td>{listing.zipCode}</td>
              <td>{listing.mapCoordinator}</td>
              <td>{listing.date}</td>
              <td>
                <i className={listing.deleteIcon}></i>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AllListing;
