import React, { useState } from "react";
import "./AllAccounts.css";

const AllAccounts = () => {
  const [isChecked, setIsChecked] = useState(false);

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };
  const accounts = [
    {
      srNo: 1,
      uid: "UID3793",
      userName: "Steven Garrett",
      userEmail: "strush59@gmail.com",
      type: "User",
      password: "idunno59",
      contact: "206-498-7961",
      listing: "0 Listing",
      verified: "no",
      verifyAccount: "",
      date: "07-19-2024",
      editIcon: "fa-solid fa-pen-to-square",
      loginIcon: "fa-solid fa-right-to-bracket",
      deleteIcon: "fa-solid fa-trash",
    },
    {
      srNo: 2,
      uid: "UID3792",
      userName: "JD Rushton",
      userEmail: "jdrushton923@gmail.com",
      type: "User",
      password: "Ava4luker2@",
      contact: "253-359-0573",
      listing: "0 Listing",
      verified: "no",
      verifyAccount: "",
      date: "07-17-2024",
      editIcon: "fa-solid fa-pen-to-square",
      loginIcon: "fa-solid fa-right-to-bracket",
      deleteIcon: "fa-solid fa-trash",
    },
    // Add more rows as needed
  ];

  return (
    <>
      <div className="table_responsive mt-5">
        <table className="tablemain">
          <thead className="tableheader">
            <tr>
              <th>
                <input
                  type="checkbox"
                  name="table-checkbox"
                  id="table-checkbox"
                />
              </th>
              <th>Sr.No</th>
              <th>UID</th>
              <th>User Name</th>
              <th>User Email</th>
              <th>Type</th>
              <th>Password</th>
              <th>Contact.no</th>
              <th>Listing</th>
              <th>Verified</th>
              <th>
                Verified <br /> Account
              </th>
              <th>Date</th>
              <th>
                Reset <br /> Credential
              </th>
              <th>Login</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {accounts.map((account, index) => (
              <tr key={index}>
                <td>
                  <input
                    type="checkbox"
                    name="table-checkbox"
                    id="table-checkbox"
                  />
                </td>
                <td>{account.srNo}</td>
                <td>{account.uid}</td>
                <td>{account.userName}</td>
                <td>{account.userEmail}</td>
                <td>{account.type}</td>
                <td>{account.password}</td>
                <td>{account.contact}</td>
                <td>{account.listing}</td>
                <td>{account.verified}</td>
                <td>
                <div className="container">
                  <label className="switch">
                    <input
                      type="checkbox"
                      id="checkbox"
                      checked={isChecked}
                      onChange={handleToggle}
                    />
                    <div
                      className={`slider round ${isChecked ? "checked" : ""}`}
                    ></div>
                  </label>
                </div>
                </td>
                <td>{account.date}</td>
                <td>
                  <i className={account.editIcon}></i>
                </td>
                <td>
                  <i className={account.loginIcon}></i>
                </td>
                <td>
                  <i className={account.deleteIcon}></i>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AllAccounts;
