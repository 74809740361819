import React, { useState } from "react";
import CommonNavbar from "../../Pages/CommonNavbar";
import Footer from "../../Pages/Footer";
import "./Blog.css";
import { Link } from "react-router-dom";
import "../MediaQuery/MediaQuery.css";

const Blog = () => {
  const [showPopular, setShowPopular] = useState(true);
  const [showRecent, setShowRecent] = useState(false);
  const [showCategories, setShowCategories] = useState(false);
  const [activeButton, setActiveButton] = useState("popular"); // New state for tracking active button

  const blogs = [
    {
      id: 1,
      title: "The Benefits of an Adult Family Home Registry",
      description:
        "Here are the benefits of adult family homes registry. Our adult family home registry allows for quick and easy access to adult family homes based on: location, senior care services, room types,and activities.",
      imgSrc:
        "https://res.cloudinary.com/ornage/image/upload/v1543564586/afh/blog/images/wqdc9lhhrgjjshen1wwz.jpg",
      date: "2020-02-03",
    },
    {
      id: 2,
      title: "Things You Need to Know About Adult Family Homes",
      description:
        "The Adult Family Home is a residential home designed to provide personal care services to individuals requiring personal assistance. ",
      imgSrc:
        "https://res.cloudinary.com/adultfamilyhomes-org/image/upload/v1566025318/afh/blog/images/bipycluzox0bwyqc2oiq.jpg",
      date: "2020-02-03",
    },
    {
      id: 3,
      title: "Choosing an Appropriate Adult Family Home for Your Loved One",
      description:
        "There are many adult family homes options. Here we give important tips to choose an appropriate adult family home for your loved one with the best family-oriented setting.",
      imgSrc:
        "https://res.cloudinary.com/adultfamilyhomes-org/image/upload/v1566825562/afh/blog/images/fib5jmzr0kh9dkbpul8c.jpg",
      date: "2020-02-03",
    },
    {
      id: 4,
      title: "Aging & Depression - Symptoms and Treatment for Elderly",
      description:
        "Depression is not a part of aging, it is a common mood disorder. Depression in the elderly is common. It's vital to realize that depression isn&#65533;t an unavoidable part of getting older.acter flaw",
      imgSrc:
        "https://res.cloudinary.com/adultfamilyhomes-org/image/upload/v1606799451/afh/blog/images/dyhslq4i5wxsk7ufslxj.jpg",
      date: "2020-02-03",
    },
    // Add more blog objects as needed
  ];
  const categories = [
    {
      category: "Adult Family Homes",
    },
    {
      category: "Senior Living",
    },
    {
      category: "Elderly Care",
    },
    {
      category: "Caregiver",
    },
    {
      category: "Healthy living",
    },
    {
      category: "Alzheimer and Dementia",
    },
    {
      category: "Aging",
    },
    {
      category: "Medicare and Medicaid",
    },
  ];
  const showPopularBlog = () => {
    setShowPopular(true);
    setShowRecent(false);
    setShowCategories(false);
    setActiveButton("popular");
  };

  const showRecentBlog = () => {
    setShowRecent(true);
    setShowPopular(false);
    setShowCategories(false);
    setActiveButton("recent");
  };

  const showCategoriesBlog = () => {
    setShowCategories(true);
    setShowPopular(false);
    setShowRecent(false);
    setActiveButton("categories");
  };

  return (
    <div>
      <div className="families">
        <CommonNavbar />
        <div className="container mt-5">
          <div className="row">
            <div className="familybanner">
              <h1 style={{ paddingBottom: "5rem" }}>Senior Care Blogs</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row p-1">
          <div className="col-lg-8 ordertwo">
            <div className="row">
              {blogs.map((blog) => (
                <div key={blog.id} className="col-lg-6 col-md-6 col-12">
                  <div className="blog">
                    <div className="blog-img">
                      <img src={blog.imgSrc} alt={blog.title} />
                    </div>
                    <h3 className="blog-tittle">{blog.title}</h3>
                    <p className="blog-description">{blog.description}</p>
                    <Link
                      // to="/blogLandingPage"
                      className="viewmore morebtn"
                      // style={{ width: "9rem" }}
                    >
                      View More
                    </Link>
                    <div className="social-icons-blog">
                      <div className="blog-facebook">
                        <i className="fa-brands fa-facebook"></i>
                      </div>
                      <div className="blog-twitter">
                        <i className="fa-brands fa-twitter"></i>
                      </div>
                      <div className="blog-linkedin">
                        <i className="fa-brands fa-linkedin"></i>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-lg-4 col-md-7 col-12 bloglatestsec orderone ordertwotab ">
            <div className="latest-blog d-flex mt-3">
              <button
                onClick={showPopularBlog}
                className={`latestbtn ${
                  activeButton === "popular" ? "active" : ""
                }`}
              >
                Most Popular
              </button>
              <button
                onClick={showRecentBlog}
                className={`latestbtn ${
                  activeButton === "recent" ? "active" : ""
                }`}
              >
                Recent
              </button>
              <button
                onClick={showCategoriesBlog}
                className={`latestbtn ${
                  activeButton === "categories" ? "active" : ""
                }`}
              >
                Categories
              </button>
            </div>
            {showPopular && (
              <>
                {blogs.map((blog) => (
                  <div className="row mt-4">
                    <div className="col-lg-4 col-md-4 col-4">
                      <div className="popular-sec-img">
                        <img src={blog.imgSrc} alt="" />
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-8 col-7">
                      <div className="popular-sec-content">
                        <p>{blog.title}</p>
                        <div className="date-sec">
                          <p>Posted On {blog.date}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
            {showRecent && (
              <>
                {blogs.map((blog) => (
                  <div className="row mt-4">
                    <div className="col-lg-4 col-md-4 col-4">
                      <div className="popular-sec-img">
                        <img src={blog.imgSrc} alt="" />
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-8 col-7">
                      <div className="popular-sec-content">
                        <p>{blog.title}</p>
                        <div className="date-sec">
                          <p>Posted On {blog.date}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
            {showCategories && (
              <>
                {categories.map((category) => (
                  <div
                    className="stars"
                    style={{ textAlign: "left", lineHeight: "2.5" }}
                  >
                    <span className="fa fa-star checked"> </span>
                    <span>{category.category}</span>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Blog;
