import React, { useState } from "react";
import "./BlogLandingPage.css";
import CommonNavbar from "../../Pages/CommonNavbar";
import Footer from "../../Pages/Footer";
import { Link } from "react-router-dom";

const BlogLandingPage = () => {
  const [showPopular, setShowPopular] = useState(true);
  const [showRecent, setShowRecent] = useState(false);
  const [activeButton, setActiveButton] = useState("popular"); // New state for tracking active button\

  const showPopularBlog = () => {
    setShowPopular(true);
    setShowRecent(false);
    setActiveButton("popular");
  };

  const showRecentBlog = () => {
    setShowRecent(true);
    setShowPopular(false);
    setActiveButton("recent");
  };

  const landingBlogs = [
    {
      id: 1,
      title: "Do's and Dont's Dealing with Dementia Behavior Problems",
      blogImg:
        "https://res.cloudinary.com/adultfamilyhomes-org/image/upload/v1574336887/afh/blog/images/tti3dviuwaeg8czlwh1n.jpg",
      blogDate: "2019-11-21",
    },
    {
      id: 2,
      title: "Health Reminders for Seniors",
      blogImg:
        "https://res.cloudinary.com/adultfamilyhomes-org/image/upload/v1579957984/afh/blog/images/rliilgg3yz2raxeurmzy.png",
      blogDate: "2020-01-27",
    },
    {
      id: 3,
      title: "Technology for the Aged: Immensely Helpful for the Elderly",
      blogImg:
        "https://res.cloudinary.com/adultfamilyhomes-org/image/upload/v1580823099/afh/blog/images/a0oiodwgk7imrzbnflgj.jpg",
      blogDate: "2020-02-10",
    },
  ];

  return (
    <>
      <CommonNavbar style={{ backgroundColor: "#0c160f" }} />
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-md-12 col-12 left-section">
            <div className="blog-section">
              <h2>
                Technology for the Aged: Immensely Helpful for the Elderly
              </h2>
              <img
                src="https://res.cloudinary.com/adultfamilyhomes-org/image/upload/v1580823099/afh/blog/images/a0oiodwgk7imrzbnflgj.jpg"
                alt="blog-landing-img"
              />
              <p className="mt-2">
                We live in a technology-laden world. It is not a startling fact
                that we are overly dependent on technology. Imagine a day
                without the technology that’s around us. The television that
                broadcasts our favorite tv shows, the gadget that you’re reading
                this onto, or the snack that you just ordered a few moments ago;
                all of this is made possible because of the improvement in
                technology. Technology is improving human lives at a rapid pace.
                Advancement in technology has been beneficial as it not only
                diagnoses everyday problems but even solves them.
              </p>
              <p className="mt-4">
                In the US, there are 108 million people who are above 50 years
                or older. According to AARP, those who age 50 or above in the
                U.S. generate $7.6 trillion in economic activity. If the elderly
                get along with the technological advancements that have been
                happening in the country, then these figures could rise.
                Therefore there is an excellent opportunity for the businesses
                to adopt methods that would not only benefit them but the
                elderly as well.{" "}
              </p>
              <p className="mt-4">
                If the elderly don’t get along with the advancements in
                technology, then there would be a massive gap between the
                younger and older generation. Undoubtedly, modern technology is
                complex. The younger generation who is compatible with the
                current technology needs to help its elderly with it. The
                technology in the elderly’s life can improve their living. It
                would also make their everyday tasks easier. With the acceptance
                of technology, the elderly can overcome the difficulties of
                modern life.
              </p>
              <p className="mt-4">
                Here’s a glimpse of the modern-day technology that would be
                immensely helpful to the elderly.
              </p>
              <div className="voice-assistant">
                <h3>Voice Assistants</h3>
                <p>
                  Voice assistant or voice commanding applications are those
                  tools where the voice command gives solutions to our problems.
                  The voice commands are also capable of answering questions.
                  The user says their questions, and the tool comes up with an
                  answer. The voice commands are useful for completing tasks as
                  well. For example, If we want our gadget to play a song, we
                  give a voice command to play that song and the output would
                  play that song. From calling someone from our phonebook to
                  increasing the temperature of your smart air conditioner,
                  voice assistant tools can help you. Phones have built-in voice
                  commanding software or we could buy a speaker that comes with
                  that feature.
                </p>
                <p>
                  The elderly can benefit immensely from such tools. As we all
                  know how their physical activity decreases with old age, these
                  tools can simplify their lives. They can tackle their
                  forgetfulness by setting calendar reminders or to-do notes.
                  They can search if the weather is suitable for an evening
                  stroll. The voice assistant tool can read the received message
                  for you or book a cab for you.
                </p>
              </div>
              <div className="hearing-aid">
                <h3>Hearing Aid</h3>
                <img
                  src="https://res.cloudinary.com/adultfamilyhomes-org/image/upload/v1580881950/afh/blog/xqoefjvrdlsfdtdg3zy6.jpg"
                  alt="hearing-aid-img"
                  className="hearing-aid-img"
                />
                <p className="mt-2">
                  A hearing aid is a device that a person wears behind his ear.
                  It simply amplifies the sound and increases its volume so that
                  a person who is suffering from deafness can hear the sound.
                  Nearly 30 million Americans could benefit from using a hearing
                  aid, according to the National Institute on Deafness and Other
                  Communication Disorders (MNIDCD). But it has been found that
                  out of five individuals, only one of them uses it. This can
                  change for the reason that there is an advancement in the way
                  hearing aids are working.
                </p>
                <p>
                  Modern-day hearing aids come up with rechargeable batteries,
                  which can last up to 30 hours. The hearing aids also come up
                  with a smartphone connectivity feature. The hearing aid
                  connects to the phone, and the user can hear the voice in the
                  hearing aid. It also acclimatizes according to the
                  surroundings to help the hearer hear sound in the noisiest
                  places.
                </p>
              </div>
              <div className="medical-apps mt-4">
                <h3>Medical Apps</h3>
                <p>
                  The health industry is booming day by day, and the elderly are
                  contributing their massive part in it. The industry is using
                  technology to cater to modern health problems. While many
                  older people are benefiting from this technology, some are
                  still lagging. A survey released by Paw Research in 2013
                  states, “7 in 10 U.S. adults track a health indicator like
                  weight, diet, exercise routine, or symptom. Technology plays a
                  modest supporting role, despite the proliferation of mobile
                  devices and apps.”
                </p>
                <p>
                  Patients and doctors both are using mHealth apps. Mhealth are
                  apps that provide support and care to patients in the form of
                  technology. These apps can enormously benefit the elderly in
                  their daily lives. Some apps offer a health summary of the
                  patients’ health. Some remind them to eat their medication
                  regularly. Patients can read detailed information about a drug
                  on the internet before consuming it. They can check the
                  symptoms, although nothing should be done without consulting a
                  medical practitioner.
                </p>
              </div>
              <div className="e-commerce-website mt-4">
                <h3>E-Commerce Website</h3>
                <p>
                  An E-commerce website is a marketplace where buying and
                  selling of commodities take place over the internet. The
                  younger generation is immensely benefiting from e-commerce
                  websites. But the elderly can benefit from e-commerce websites
                  as well. It not only saves their time but they can shop
                  according to their choices. As the e-commerce market is
                  available 24/7, they can shop at any time. There is no need
                  for them to be present physically, which saves their precious
                  energy.
                </p>
                <p>
                  E-commerce websites provide home delivery of food, medicines,
                  clothes, and many household items. Imagine you have a craving
                  after a heavy meal for a dessert, but you cannot make it. So
                  what would you do? An online food delivery option lets you
                  order food at any given time so that even your midnight
                  cravings find a refuge. For the elderly, it can be challenging
                  to keep the count of medicine. In old age, it is difficult to
                  keep track of the dates, let alone keeping track of the
                  medicines. But they can now order medicine over the internet.
                  A medical practitioner's prescription is a prerequisite for
                  placing an order.
                </p>
              </div>
              <div className="entertainment mt-4">
                <h3>Entertainment</h3>
                <img
                  src="https://res.cloudinary.com/adultfamilyhomes-org/image/upload/v1580883655/afh/blog/zj5b1mgdjmg2tjbtpudn.jpg"
                  alt=""
                />
                <p className="mt-3">
                  When we are young, we seldom get time to entertain ourselves.
                  Our work environment and responsibilities prevent ourselves
                  from doing this. This changes as we grow old. Older
                  individuals have a lot of time to do what they always wanted
                  to do as a younger self. They can visit places that they
                  always wanted to. They can go out for holidays for as much as
                  their pockets permits. The best part is they can do this all
                  alone as well. Modern technology can help them with that. Some
                  apps can help an individual with booking flight tickets, book
                  hotels, find direction, and discovering that place.
                </p>
                <p className="mt-3">
                  We can watch any movie on various digital platforms. These
                  digital platforms quench our thirst to watch the movie that we
                  were always longing for. The digital platforms also sport TV
                  shows. TV shows are sorted according to their genre so that
                  the user can watch these shows according to his liking. Users
                  can also use the voice assistant to search for movies or TV
                  shows with the help of their TV remote.{" "}
                </p>
              </div>
              <div className="social-medias">
                <h3>Social Media</h3>
                <p>
                  We all know as we grow old, we tend to be lonelier than we
                  were as a younger person. According to a report by
                  Administration for Community Living, 13.8 million older adults
                  live alone in the United States. These people are likely to
                  feel lonely at times. Social media space helps them get over
                  their loneliness by connecting them with different types of
                  people. Sharing our thoughts and opinions can be difficult in
                  old age but on social media older people can acquire views of
                  their own. This benefits them to clear their mind and makes
                  them feel less lonely.{" "}
                </p>
                <p>
                  Social media helps in community building for older adults.
                  Social media is a digital space where they can connect to
                  their peers, family, and friends. It can be enlightening for
                  an individual to be on a social media platform as there is so
                  much information in there. For a social sphere to thrive, it
                  should encourage discussion. Social media encourages
                  discussion and lets everyone become a part of it.
                </p>
                <div className="other-gadgets">
                  <h3>Other Gadgets</h3>
                  <p>
                    On June 29th, 2007, Steve Jobs unveiled the first iPhone.
                    Eight years later, Apple introduced us to another benchmark
                    product- The Apple iWatch. This watch can hugely benefit the
                    elderly as it keeps track of the heart rate. It helps to
                    keep track of your exercises and diet. It can even benefit
                    you when you are sleeping by keeping track of your sleep
                    patterns and notifies you if you are not getting adequate
                    sleep. Smartwatches can also be beneficial for your safety.
                    The elderly suffering from Alzheimer's is prone to get lost.
                    The GPS in the smartwatch can help in tracking their
                    location.
                  </p>
                </div>
              </div>
            </div>
            <div className="blog-comment mt-4">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-12">
                  <input type="text" name="" id="" placeholder="Name" />
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <input type="email" name="" id="" placeholder="Email" />
                </div>
                <div className="col-lg-12 col-md-12 col-12 mt-2">
                  <textarea name="" id="landing-textarea"></textarea>
                </div>
                <div className="col-lg-12 col-md-12 col-12 mt-4 mb-4">
                  <Link to="" className="landing-submit-btn">
                    Submit
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-12 col-12 right-section">
            <div className="landing-recent-blog ">
              <div className="recent-blog d-flex">
                <button
                  onClick={showPopularBlog}
                  className={`latestbtn ${
                    activeButton === "popular" ? "active" : ""
                  }`}
                >
                  Most Popular
                </button>
                <button
                  onClick={showRecentBlog}
                  className={`latestbtn latest-recent ${
                    activeButton === "recent" ? "active" : ""
                  }`}
                >
                  Recent
                </button>
              </div>

              {showPopular && (
                <>
                  {landingBlogs.map((blog) => (
                    <div className="row mt-4">
                      <div className="col-lg-3 col-md-3 col-4 landing-blog-post">
                        <div className="popular-sec-img">
                          <img src={blog.blogImg} alt="" />
                        </div>
                      </div>
                      <div className="col-lg-7 col-md-7 col-6 landing-blog-post">
                        <div className="popular-sec-content">
                          <p>{blog.title}</p>
                          <div className="date-sec">
                            <p>Posted On {blog.blogDate}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
              {showRecent && (
                <>
                  {landingBlogs.map((blog) => (
                    <div className="row mt-4">
                      <div className="col-lg-3 col-md-3 col-4 landing-blog-post">
                        <div className="popular-sec-img">
                          <img src={blog.blogImg} alt="" />
                        </div>
                      </div>
                      <div className="col-lg-7 col-md-7 col-6 landing-blog-post">
                        <div className="popular-sec-content">
                          <p>{blog.title}</p>
                          <div className="date-sec">
                            <p>Posted On {blog.blogDate}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
            <div className="newspaper mt-4">
              <h3>Newsletter Signup</h3>
              <div className="line"></div>
              <p className="mt-2">
                Get the latest news, tips, and advice on senior care and adult
                family homes.
              </p>
            </div>
            <div className="landing-blog-email d-flex">
              <input type="email" name="" id="" placeholder="Email" />
              <Link to="" className="subscribe-btn">
                Subscribe
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BlogLandingPage;
