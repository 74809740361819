import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "./AdminSideNav.css";
import {
  RiArrowDownSLine,
  RiArrowLeftSLine,
  RiDashboardFill,
  RiMenu2Fill,
} from "react-icons/ri";
import { IoMdContact } from "react-icons/io";
import { FaList, FaLayerGroup, FaMobileAlt, FaRocket } from "react-icons/fa";
import { MdAccountBalance } from "react-icons/md";

const AdminSideNav = () => {
  const [dropdowntoggle1, setDropdownToggle1] = useState(false);
  const [dropdowntoggle2, setDropdownToggle2] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const sidebarRef = useRef(null);

  const toggleDropdown1 = () => {
    setDropdownToggle1(!dropdowntoggle1);
    setDropdownToggle2(false);
  };

  const toggleDropdown2 = () => {
    setDropdownToggle2(!dropdowntoggle2);
    setDropdownToggle1(false);
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsSidebarOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="admin-side-main" ref={sidebarRef}>
      <div className="admin-menu">
        <RiMenu2Fill
          onClick={() => {
            setIsSidebarOpen(!isSidebarOpen);
            setDropdownToggle1(false);
            setDropdownToggle2(false);
          }}
        />
      </div>

      {/* <div
        className={`d-flex flex-column side-menu-bar ${
          isSidebarOpen ? "open" : "closed"
        }`}
      > */}

      <div className="d-flex flex-column admin-side-menubar ">
        <NavLink to="" className="admin-side-menubar-dec">
          <div className="admin-side-menubar-one">
            <RiDashboardFill className="admin-menu-icon" />
            {isSidebarOpen && <span>Dashboard</span>}
          </div>
        </NavLink>
        <NavLink to="/supernova/contact-us" className="admin-side-menubar-dec">
          <div className="admin-side-menubar-one">
            <IoMdContact className="admin-menu-icon" />
            {isSidebarOpen && <span>Contact Us</span>}
          </div>
        </NavLink>
        <NavLink to="/supernova/all-listing" className="admin-side-menubar-dec">
          <div className="admin-side-menubar-one">
            <FaList className="admin-menu-icon" />
            {isSidebarOpen && <span>All Listing</span>}
          </div>
        </NavLink>

        <NavLink
          to="/supernova/all-accounts"
          className="admin-side-menubar-dec"
        >
          <div className="admin-side-menubar-one">
            <MdAccountBalance className="admin-menu-icon" />
            {isSidebarOpen && <span> All Accounts</span>}
          </div>
        </NavLink>

        <div
          className={`dropdown d-flex justify-content-between admin-side-menubar-one admin-side-menubar-dec ${
            dropdowntoggle1 ? "active" : ""
          }`}
          onClick={() => {
            toggleDropdown1();
            setIsSidebarOpen(true);
          }}
          aria-expanded={dropdowntoggle1}
        >
          <span>
            <FaLayerGroup className="admin-menu-icon me-2" />
            {isSidebarOpen && <span>Leads</span>}
          </span>
          <span>
            {isSidebarOpen ? (
              <span>
                {dropdowntoggle1 ? (
                  <RiArrowDownSLine className=" admin-arrow-icon" />
                ) : (
                  <RiArrowLeftSLine className=" admin-arrow-icon" />
                )}
              </span>
            ) : (
              <RiArrowLeftSLine className=" admin-arrow-icon" />
            )}
          </span>
        </div>
        <span>
          {isSidebarOpen ? (
            <>
              {dropdowntoggle1 ? (
                <span>
                  <div className="admin-side-menubar-one-drop">
                    <NavLink
                      to="/send-leads"
                      className="admin-dropdown-item mt-2"
                    >
                      Send your Leads
                    </NavLink>
                  </div>
                  <div className="admin-side-menubar-one-drop">
                    <NavLink
                      to="/all-leads"
                      className="admin-dropdown-item mt-2"
                    >
                      All Leads
                    </NavLink>
                  </div>
                </span>
              ) : (
                " "
              )}
            </>
          ) : (
            " "
          )}
        </span>

        <NavLink to="/provider-application" className="admin-side-menubar-dec">
          <div className="admin-side-menubar-one">
            <FaMobileAlt className="admin-menu-icon" />
            {isSidebarOpen && <span>Provider Aplications</span>}
          </div>
        </NavLink>

        <div
          className={`dropdown d-flex justify-content-between admin-side-menubar-one admin-side-menubar-dec ${
            dropdowntoggle2 ? "active" : ""
          }`}
          onClick={() => {
            toggleDropdown2();
            setIsSidebarOpen(true);
          }}
          aria-expanded={dropdowntoggle2}
        >
          <span>
            <FaRocket className="admin-menu-icon me-2" />
            {isSidebarOpen && <span>SEO</span>}
          </span>
          <span>
            {isSidebarOpen ? (
              <span>
                {dropdowntoggle2 ? (
                  <RiArrowDownSLine className=" admin-arrow-icon" />
                ) : (
                  <RiArrowLeftSLine className=" admin-arrow-icon" />
                )}
              </span>
            ) : (
              <RiArrowLeftSLine className=" admin-arrow-icon" />
            )}
          </span>
        </div>

        <span>
          {isSidebarOpen ? (
            <>
              {dropdowntoggle2 ? (
                <span>
                  <div className="admin-side-menubar-one-drop">
                    <NavLink
                      to="/static-page"
                      className="admin-dropdown-item mt-2"
                    >
                      Static Page
                    </NavLink>
                  </div>
                  <div className="admin-side-menubar-one-drop">
                    <NavLink
                      to="/city-static-page"
                      className="admin-dropdown-item mt-2"
                    >
                      City Static Page
                    </NavLink>
                  </div>
                  <div className="admin-side-menubar-one-drop">
                    <NavLink to="/sitemap" className="admin-dropdown-item mt-2">
                      Sitemap
                    </NavLink>
                  </div>
                  <div className="admin-side-menubar-one-drop">
                    <NavLink
                      to="/cloudinary"
                      className="admin-dropdown-item mt-2"
                    >
                      Cloudinary
                    </NavLink>
                    <div className="admin-side-menubar-one-drop">
                      <NavLink to="/blog" className="admin-dropdown-item mt-2">
                        Blog
                      </NavLink>
                    </div>
                    <div className="admin-side-menubar-one-drop">
                      <NavLink to="/event" className="admin-dropdown-item mt-2">
                        Event
                      </NavLink>
                    </div>
                    <div className="admin-side-menubar-one-drop">
                      <NavLink to="/video" className="admin-dropdown-item mt-2">
                        Video
                      </NavLink>
                    </div>
                    <div className="admin-side-menubar-one-drop">
                      <NavLink
                        to="/upload-dashboard-videos"
                        className="admin-dropdown-item mt-2"
                      >
                        Upload DashBoard Videos
                      </NavLink>
                    </div>
                    <div className="admin-side-menubar-one-drop">
                      <NavLink
                        to="/gallery"
                        className="admin-dropdown-item mt-2"
                      >
                        Gallery
                      </NavLink>
                    </div>
                  </div>
                </span>
              ) : (
                " "
              )}
            </>
          ) : (
            " "
          )}
        </span>
      </div>
    </div>
  );
};

export default AdminSideNav;
